import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 10px;

  label {
    display: flex;
    flex-direction: column;
  }

  select {
    margin: 10px 0 10px 0;
    padding: 10px;
    width: 100%;
    border-radius: 6px;
    font-size: 16px;
  }
`;
