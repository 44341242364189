export default {
  backgroundColor: '#EDEDED',
  primaryColor: '#FF6B35',
  secondaryColor: '#004E89',
  inputBackground: '#E1E8F2',
  buttonBackground: '#F5F5F5',
  hoverButtonBackground: '#EBEBEB',
  iconsColor: '#919191',
  secondaryFontColor: '#525252',
  dangerButton: '#FF0F0F',
  backgroundNotificationColor: '#DCE0E8',

};

export const breakpoints = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1024px',
};
