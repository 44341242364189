import { Alert, AlertTitle } from '@mui/material';
import { useEffect } from 'react';
import { Container } from './styles';
import { useErrors } from '../../context/ErrorContext';

export default function ErrorAlert() {
  const { error, clearError } = useErrors();

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        clearError();
      }, 7000);

      return () => clearTimeout(timer);
    }
  }, [error, clearError]);

  if (!error) return null;

  return (
    <Container>
      <Alert severity="error" onClose={clearError}>
        <AlertTitle style={{
          fontSize: '20px',
        }}
        >
          Erro:
        </AlertTitle>
        {error}
      </Alert>
    </Container>
  );
}
