import styled from 'styled-components';

export const Container = styled.main`
  height: 100%;
  overflow-y: auto;

  button {
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .cancel-images-to-send-button {
    margin: 20px 0 0 40px;
    border-radius: 50%;
    padding: 10px;
    transition: 0.2s ease-in;

    &:hover {
      filter: brightness(0.9);
    }
  }

  .send-media-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 80%;
    margin: 70px 0 50px 0;

    .main-image {
      margin-top: 40px;
      max-height: 60%;
      height: auto;
      width: auto;
      box-shadow: -1px 2px 8px 2px rgba(0, 0, 0, 0.2);
    }

    .preview-images {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;

    .images-container {
      max-width: 500px;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      padding-top: 12px;

    &::-webkit-scrollbar {
    width: 10px;
    height: 6px;
    border: 1px solid #AEBAC1;
    border-radius: 20px;
  }

    &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: rgba(0,0,0,0.2);
  }
    }

    .preview-images-button {
      width: 80px;
      height: 80px;
      margin-right: 15px;
      position: relative;
    }

    img {
      height: 80px;
      width: 80px;
      box-shadow: none;
      border-radius: 5px;
      transition: 0.2s ease-in;
    }

    img:hover{
      filter: brightness(0.9);
    }

    .delete-preview-image-button {
      background-color: ${({ theme }) => theme.buttonBackground};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 1;
      padding: 5px;
      position: absolute;
      right: -5px;
      top: -5px;
      transition: 0.2s ease-in;
      border: 1px solid #AEBAC1;
      box-shadow: 0 1px 1px 0 rgba(11, 20, 26, .06), 0 2px 5px 0 rgba(11, 20, 26, .2);

      &:hover {
        background-color: ${({ theme }) => theme.secondaryColor};
        border-color: ${({ theme }) => theme.secondaryColor};
        svg {
          fill: white;
        }
      }
    }

    .more-images-button {
      position: relative;
      height: 80px;
      width: 80px;
      margin-top: 8px;
      border: 1px solid #AEBAC1;
      margin-left: 10px;
    }

  }

  }

`;
