import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
margin: 0 5px 10px 5px;
margin-bottom: 10px;
align-items: flex-start;

.message-content-div {
  border-bottom: 20px;
  border-radius: 10px;
  min-width: 20%;
  word-wrap: break-word;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.secondaryFontColor};
  }

  audio {
    max-width: 100%;
  }

  embed {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header-message-div {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      font-size: 12px;
    }
  }

  .message-media-div {
    padding: 5px;
    max-width: 300px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 500px;
      height: 200px;
      border-radius: 4px;

      svg {
        color: ${({ theme }) => theme.secondaryColor};
        position: absolute;
        background-color: ${({ theme }) => theme.inputBackground};
        border-radius: 6px;
        bottom: 36%;
        right: 38%;
        font-size: 60px;
      }
    }
  }

  .time-and-confirmation-span {
    margin-top: 5px;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: end;
  }
`;
