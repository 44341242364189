import styled from 'styled-components';

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

h1 {
  font-weight: 600;
  text-align: start;
  display: flex;
  align-items: start;
  font-size: 18px;
}

h1:first-child {
  font-size: 20px;
  padding: 15px;
  border-bottom: 1px solid #DDD;
  font-weight: 700;
}

.send-message-button {
  color: white;
  background-color: ${({ theme }) => theme.secondaryColor};
  padding: 6px 8px;
  font-size: 16px;
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  margin: 10px 0px;
}

.send-message-button:hover {
  background-color: ${({ theme }) => theme.secondaryColor};
  filter: brightness(0.8);
}

.send-message-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
 }

p {
  margin-top: 10px;
  font-weight: 600;
}

input, textarea {
  width: 250px;
  border: 1px solid #DDD;
  font-size: 16px;
  padding: 5px;
  margin-top: 5px;
  border-radius: 8px;
  resize: none;
}

textarea {
  height: 100px;
}

img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0 15px 0 5px;
  }


`;
