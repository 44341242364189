import styled from 'styled-components';

export const Container = styled.div`
  width: 40%;
  border-left: 1px solid #DDD;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;

  .cancel-edit-contact-button {
    align-self: self-start;
    margin-left: 5%;
    padding: 5px;
    border-radius: 50%;
    transition: 0.2s ease-out;

    &:hover {
        filter: brightness(0.8);
      }
  }

  .profile-img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 20px 10px;
    border-bottom: 1px solid #DDD;

    h1 {
    font-size: 24px;
    }

    img {
    border-radius: 50%;
    padding: 10px;
    display: flex;
    height: auto;
    width: 60%;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    transition: 0.2s ease-in;
    margin-bottom: 30px;
  }

    span {
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px 0 10px 0;
      width: 70%;

      input {
      padding: 6px 8px;
      border-radius: 8px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      border: none;
      width: 100%;
    }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        font-size: 16px;
        padding: 5px;
        border-radius: 50%;
        transition: 0.2s ease-out;

        &:hover {
          background-color: #DDD;
        }
      }
    }
  }

  .profile-fields-wrapper {
    width: 100%;
    padding: 20px 10px 20px 10px;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;

    h2 {
      font-size: 20px;
    }

    textarea {
      text-align: left;
      margin-top: 10px;
      height: 30%;
      width: 100%;
      padding: 6px;
      font-size: 16px;
      overflow: auto;
      border: 1px solid #AEBAC1;
      resize: vertical;
    }

    button {
      align-self: center;
      color: white;
      background-color: ${({ theme }) => theme.secondaryColor};
      padding: 6px 8px;
      font-size: 16px;
      border-radius: 6px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      margin: 20px 0px;

      &:hover {
        background-color: ${({ theme }) => theme.secondaryColor};
        filter: brightness(0.8);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

`;
