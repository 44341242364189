import { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChatContext } from '../../context/ChatContext';
import { useErrors } from '../../context/ErrorContext';
import { baseUrl, getRequest } from '../../utils/services';

export default function RedirectHandlerForAbrirChat() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setCurrentChat } = useContext(ChatContext);
  const { setErrorMessage } = useErrors();

  useEffect(() => {
    const handleSetCurrentChat = async () => {
      const queryParams = new URLSearchParams(location.search);
      const sender = queryParams.get('sender');
      const fromMsg = queryParams.get('fromMsg');

      const findTicket = async (sender, fromMsg) => {
        const response = await getRequest(`${baseUrl}/chats/find/${sender}/${fromMsg}`);

        if (response.error) {
          setErrorMessage('Erro ao abrir chat! Entre em contato com o suporte.');
          return console.error('error finding ticket: ', response.error);
        }

        return response;
      };

      if (sender && fromMsg) {
        const chatToOpen = await findTicket(sender, fromMsg);
        setCurrentChat(chatToOpen);
        navigate('/chat');
      } else {
        navigate('/');
      }
    };
    handleSetCurrentChat();
  }, [location.search, navigate, setCurrentChat]);

  return null;
}
