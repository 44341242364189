import { useContext, useState } from 'react';
import { Container } from './styles';
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';

export default function TransferTicketSection() {
  const [filteredAttendants, setFilteredAttendants] = useState([]);

  const { user } = useContext(AuthContext);
  const {
    allUsers,
    departments,
    selectedDepartment,
    selectedAttendant,
    setSelectedDepartment,
    setSelectedAttendant,
  } = useContext(ChatContext);

  const handleDepartmentChange = (e) => {
    const { value } = e.target;

    setSelectedAttendant('default');

    if (value === 'default') return setSelectedDepartment(value);

    const selectedSectorId = parseInt(value, 10);
    setSelectedDepartment(selectedSectorId);

    const attendantsInSelectedSector = allUsers.filter((user) => user.setores
      .some((setor) => setor.id_setor === selectedSectorId));

    setFilteredAttendants(attendantsInSelectedSector);
  };

  const handleAttendantChange = (e) => {
    const { value } = e.target;

    if (value === 'default') return setSelectedAttendant(value);

    const selectedAttendantId = parseInt(value, 10);
    setSelectedAttendant(selectedAttendantId);
  };

  return (
    <Container>
      <label htmlFor="departments">
        Departamento:
        <select
          required
          name="departments"
          id="departments"
          value={selectedDepartment}
          onChange={handleDepartmentChange}
        >
          <option value="default">Selecione...</option>
          {
            departments.map((department, index) => (
              <option
                key={index}
                value={department.id}
              >
                {department?.nome_setor}
              </option>
            ))
          }

        </select>
      </label>
      <label htmlFor="attendant">
        Atendente: (Opcional)
        <select
          name="attendant"
          id="attendant"
          disabled={selectedDepartment === 'default'}
          value={selectedAttendant}
          onChange={handleAttendantChange}
        >
          <option value="default">Selecione...</option>
          {filteredAttendants.map((user, index) => (
            <option
              key={index}
              value={user.id}
            >
              {user.name}
            </option>
          ))}

        </select>
      </label>
    </Container>
  );
}
