import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '../../assets/styles/global';
import Router from '../../Router';

import defaultTheme from '../../assets/styles/themes/default';
import { AuthContextProvider } from '../../context/AuthContext';
import { ErrorProvider } from '../../context/ErrorContext';
import { Container } from './styles';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles />
        <Container>
          <ErrorProvider>
            <AuthContextProvider>
              <Router />
            </AuthContextProvider>
          </ErrorProvider>
        </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
