import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 480px;
  padding: 0 20px;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    top: 25%;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
  }

  .changePhoto {
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    transition: 0.2s ease-in;
    margin-bottom: 50px;
  }

  button:hover {
    filter: brightness(0.8);
  }

  .custom-file-upload {
    margin-top: 10px;
    position: relative;
    display: inline-block;
    border-radius: 50%;
  }

  .custom-file-upload input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  z-index: 1;
  opacity: 0;
}

.update-password-div {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    align-items: center;

    input {
      padding: 6px 8px;
      border-radius: 8px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      border: none;
      width: 250px;
      margin-bottom: 10px;
    }

    button {
      background-color: ${({ theme }) => theme.secondaryColor};
      color: white;
      padding: 6px 8px;
      border-radius: 6px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }

    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

.update-username-div {
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
  padding: 6px 8px;
  border-radius: 8px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  width: 250px;
  margin-bottom: 10px;
  }

  button {
    background-color: ${({ theme }) => theme.secondaryColor};
    color: white;
    padding: 6px 8px;
    border-radius: 6px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }

  button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
 }

}`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin: 20px 0 0 20px;
  border-radius: 50%;
  transition: 0.1s ease-in;

  &:hover {
    background-color: lightgrey;
  }
`;
