/* eslint-disable react/prop-types */
import {
  createContext,
  useContext,
  useState,
} from 'react';

const ErrorContext = createContext();

export function ErrorProvider({ children }) {
  const [error, setError] = useState(null);

  const setErrorMessage = (message) => setError(message);
  const clearError = () => setError(null);

  return (
    <ErrorContext.Provider value={{ error, setErrorMessage, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
}

export const useErrors = () => useContext(ErrorContext);
