import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useContext } from 'react';
import { Container } from './styles';
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';

export default function UserMenu() {
  const { logoutUser } = useContext(AuthContext);
  const {
    setCurrentChat,
    setIsEditingProfile,
    setUserChats,
    setOpenTickets,
    setSortedChats,
    setUserChatsQueue,
  } = useContext(ChatContext);

  const handleLogoutClick = () => {
    logoutUser([]);
    setCurrentChat(null);
    setUserChats([]);
    setUserChatsQueue([]);
    setSortedChats([]);
    setOpenTickets([]);
  };

  const handleEditProfileClick = () => {
    setIsEditingProfile(true);
  };

  return (
    <Container>
      <button
        type="button"
        aria-label="Alterar senha"
        onClick={handleEditProfileClick}
      >
        <AccountCircleIcon
          style={{ color: '#919191' }}
          fontSize="large"
        />
        <h1>Alterar senha</h1>
      </button>
      <button
        type="button"
        aria-label="Encerrar sessão"
        onClick={handleLogoutClick}
      >
        <LogoutIcon
          style={{ color: '#919191' }}
          fontSize="large"
        />
        <h1>Sair</h1>
      </button>
    </Container>
  );
}
