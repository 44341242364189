import { useContext, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Snackbar, Alert } from '@mui/material';
import { Container, LoginForm } from './styles';
import { AuthContext } from '../../context/AuthContext';
import EditProfile from '../../components/EditProfile';

export default function Home() {
  const {
    loginInfo,
    updateLoginInfo,
    loginUser,
    passwordChangeMessage,
    setPasswordChangeMessage,
    isLoginLoading,
    loginError,
    needsPasswordReset,
  } = useContext(AuthContext);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Container>
      <header>
        <img src="https://ipbxinteligente.com.br/wp-content/uploads/2020/06/telein.png" alt="Logomarca Telein" />
      </header>

      {needsPasswordReset ? (<EditProfile />) : (
        <LoginForm onSubmit={loginUser}>
          <div>
            <h2
              id="email"
            >
              E-mail
            </h2>
            <input
              aria-labelledby="email"
              type="email"
              onChange={(e) => updateLoginInfo({
                ...loginInfo, email: e.target.value,
              })}
            />
          </div>

          <div>
            <h2
              id="password"
            >
              Senha
            </h2>
            <input
              aria-labelledby="password"
              type={passwordVisible ? 'text' : 'password'}
              onChange={(e) => updateLoginInfo({
                ...loginInfo, password: e.target.value,
              })}
            />

            <button
              type="button"
              aria-label="Exibe a senha digitada"
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
            </button>

          </div>

          {(loginError?.error)
        && (
        <div style={{
          marginBottom: '15px',
        }}
        >
          <p>
            {loginError?.message}
          </p>
        </div>
        )}

          <button type="submit">
            {isLoginLoading
              ? 'Fazendo login...'
              : 'Login'}
          </button>
        </LoginForm>
      )}

      {passwordChangeMessage !== '' && (
      <Snackbar
        autoHideDuration={5000}
        onClose={() => setPasswordChangeMessage('')}
        open
      >
        <Alert
          variant="filled"
          severity={needsPasswordReset
            ? 'info'
            : 'success'}
          onClose={() => setPasswordChangeMessage('')}
        >
          {passwordChangeMessage}
        </Alert>
      </Snackbar>
      )}
    </Container>
  );
}
