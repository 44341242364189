import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { getRequest, baseUrl } from '../../utils/services';
import { ChatContext } from '../../context/ChatContext';
import usePhoneFormatter from '../../hooks/usePhoneFormatter';
import { AuthContext } from '../../context/AuthContext';
import { useErrors } from '../../context/ErrorContext';

export default function NewChat({ initialParams = null }) {
  const [message, setMessage] = useState('');
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const { user } = useContext(AuthContext);
  const {
    sendMessage,
    currentChat,
    setCurrentChat,
    isOpenTicketsLoading,
    updateUserChatFromQueueToChats,
    setMessages,
  } = useContext(ChatContext);

  const { setErrorMessage } = useErrors();

  const [phone, handlePhoneChange, setPhone, getRawPhoneNumber] = usePhoneFormatter();

  const handleMessageInput = (event) => {
    const { value } = event.target;
    setMessage(value);
  };

  useEffect(() => {
    if (initialParams) {
      setPhone(initialParams.telefone);
      setMessage(initialParams.msg);
    }
  }, [initialParams, setPhone, setMessage]);

  const sendersKeys = Object.keys(user.senders);

  const handleFindChat = async () => {
    let newCurrentChat = currentChat;

    const response = await getRequest(`${baseUrl}/chats/find/${sendersKeys[0]}/${getRawPhoneNumber()}@s.whatsapp.net`);

    if (response.error) {
      setErrorMessage('Erro ao enviar mensagem. Entre em contato com o suporte.');
      return console.error('error finding ticket: ', response.error);
    }

    newCurrentChat = response;
    setCurrentChat(newCurrentChat);

    await updateUserChatFromQueueToChats(newCurrentChat);
  };

  const handleSendMessage = async () => {
    setIsSendingMessage(true);

    const rawFromMsg = `${getRawPhoneNumber()}@s.whatsapp.net`;

    const messagePayload = {
      body: `*${user.name}*:\n ${message}`,
      fromMe: 1,
      sender: sendersKeys[0],
      fromMsg: rawFromMsg,
      createdAt: Date.now(),
      mediaType: '',
    };

    await handleFindChat();

    await sendMessage(message, getRawPhoneNumber(), setMessage);

    setMessages((prevMessages) => [messagePayload, ...prevMessages]);

    setIsSendingMessage(false);
  };

  return (
    <Container>
      <h1>Inicie uma nova conversa</h1>
      <div>
        <p>Telefone: </p>
        <input
          value={phone}
          onChange={handlePhoneChange}
          type="tel"
        />
        <p>Mensagem: </p>
        <textarea
          value={message}
          type="text"
          onChange={handleMessageInput}
        />
      </div>
      <button
        disabled={!phone || message === '' || isOpenTicketsLoading}
        className="send-message-button"
        type="button"
        onClick={handleSendMessage}
      >
        {isSendingMessage ? 'Enviando...' : 'Enviar'}
      </button>
    </Container>
  );
}

NewChat.propTypes = {
  initialParams: PropTypes.shape({
    telefone: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
  }),
};
