import PropTypes from 'prop-types';

import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import { Badge } from '@mui/material';

import {
  Container,
  StyledButton,
} from './styles';

export default function SidebarSections({
  activeSection,
  onSectionClick,
  openedTickets,
  userChatQueue,
}) {
  return (
    <Container>
      <StyledButton
        type="button"
        issectionactive={activeSection === 'chats'}
        onClick={() => onSectionClick('chats')}
      >
        <Badge
          badgeContent={openedTickets}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          color="primary"
        >
          <ChatOutlinedIcon />
        </Badge>
        Chats
      </StyledButton>
      <StyledButton
        type="button"
        issectionactive={activeSection === 'fila'}
        onClick={() => onSectionClick('fila')}
      >
        <Badge
          badgeContent={userChatQueue}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          color="error"
        >
          <InboxOutlinedIcon />
        </Badge>
        Fila
      </StyledButton>
      <StyledButton
        type="button"
        issectionactive={activeSection === 'contatos'}
        onClick={() => onSectionClick('contatos')}
      >
        <ContactPageOutlinedIcon />
        Contatos
      </StyledButton>
    </Container>
  );
}

SidebarSections.propTypes = {
  activeSection: PropTypes.string.isRequired,
  onSectionClick: PropTypes.func.isRequired,
  openedTickets: PropTypes.number.isRequired,
  userChatQueue: PropTypes.number.isRequired,
};
