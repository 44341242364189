import { useEffect, useState } from 'react';
import { baseUrl, getRequest } from '../utils/services';

export const useFetchRecipientUser = (chat, user) => {
  const [recipientUser, setRecipientUser] = useState(null);
  const [error, setError] = useState(null);

  const recipientId = user?.id === chat?.firstId
    ? chat?.secondId
    : chat?.firstId;

  useEffect(() => {
    const getUser = async () => {
      if (!recipientId) return null;

      const response = await getRequest(`${baseUrl}/users/find/${recipientId}`);

      if (!response) {
        return setError({ error: 'No response from server' });
      }
      setRecipientUser(response);
    };

    getUser();
  }, [chat,
    user,
    recipientId]);

  return { recipientUser };
};
