import { Routes, Route, Navigate } from 'react-router-dom';

import { useContext } from 'react';
import Home from './pages/Home';
import Chat from './pages/Chat';
import RedirectHandler from './components/RedirectHandler';
import RedirectHandlerForAbrirChat from './components/RedirectHandlerForAbrirChat';
import { AuthContext } from './context/AuthContext';
import { ChatContextProvider } from './context/ChatContext';

export default function Router() {
  const { user } = useContext(AuthContext);
  return (
    <ChatContextProvider user={user}>
      <Routes>
        <Route path="/" element={user && !user.needsPasswordReset ? <Chat /> : <Home />} />
        <Route path="/chat" element={user && !user.needsPasswordReset ? <Chat /> : <Home />} />
        <Route path="/send" element={user ? <RedirectHandler /> : <Home />} />
        <Route path="/abrirChat" element={user ? <RedirectHandlerForAbrirChat /> : <Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ChatContextProvider>
  );
}
