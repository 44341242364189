import { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { PropTypes } from 'prop-types';
import { Container } from './styles';

export default function PopOverMenu({
  icon,
  tooltipTitle,
  children,
  openPopover = false,
  onClosePopover,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'center' },
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onClosePopover) {
      onClosePopover();
    }
  };

  useEffect(() => {
    if (openPopover && !anchorEl) {
      setAnchorEl(document.getElementById('open-icon'));
    } else if (!openPopover) {
      setAnchorEl(null);
    }
  }, [openPopover]);

  const open = Boolean(anchorEl);

  const id = open
    ? 'simple-popover'
    : undefined;

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <IconButton
          id="open-icon"
          aria-label="Ícone do menu"
          aria-describedby={id}
          onClick={handleClick}
          {...props}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Container
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {children}
      </Container>
    </>
  );
}

PopOverMenu.propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  openPopover: PropTypes.bool,
  onClosePopover: PropTypes.func,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  }),
};
