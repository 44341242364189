import { Container } from './styles';

export default function ChatIntro() {
  return (
    <Container>
      <img src="https://ipbxinteligente.com.br/wp-content/uploads/2020/06/telein.png" alt="Logomarca Telein" />

      <h1>Selecione um contato para iniciar uma conversa</h1>
      <h2>O Whatsapp conecta ao seu telefone para sincronizar suas mensagens.</h2>
    </Container>
  );
}
