// src/pages/RedirectHandler.js

import { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChatContext } from '../../context/ChatContext';

export default function RedirectHandler() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setShowNewChat, setInitialParamsForNewChat } = useContext(ChatContext);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const telefone = queryParams.get('telefone');
    const msg = queryParams.get('msg');

    if (telefone && msg) {
      setInitialParamsForNewChat({ telefone, msg });
      setShowNewChat(true);
      navigate('/chat');
    } else {
      navigate('/');
    }
  }, [location.search,
    navigate,
    setShowNewChat,
    setInitialParamsForNewChat]);

  return null;
}
