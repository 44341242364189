import styled from 'styled-components';

export const Container = styled.main`
  margin-top: 50px;
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
   header {
    margin-bottom: 75px;

    h1:first-child {
      color: ${({ theme }) => theme.primaryColor};
    }

    h1 {
      display: inline;
      color: ${({ theme }) => theme.secondaryColor};
      font-size: 50px;
    }

    img {
      width: 250px;
      height: auto;
    }
   }
`;

export const LoginForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    h2 {
      display: inline;
      margin-right: 20px;
      font-size: 20px;
    }

    input {
      height: 29px;
      border: none;
      padding: 4px 10px;
      font-size: 16px;
      border-radius: 6px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      transition: border-color 0.2s ease-in;
    }
  }

  div:nth-child(2) {
    margin-bottom: 20px;

    input {
      border-radius: 6px 0 0 6px;
      width: 48%;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6%;
      height: 30px;
      border-radius: 0 6px 6px 0;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }
  }

  button {
      padding: 8px 20px;
      border-radius: 8px;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.secondaryColor};
      color: white;
      font-size: 16px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }

    button:hover {
      filter: brightness(0.7);
    }

  p {
    color: ${({ theme }) => theme.dangerButton};
  }
`;
