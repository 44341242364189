import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #DDD;
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 15px;
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  background-color: transparent;
  transition: all 0.1s ease-in;
  border-bottom: 2px solid #EDEDED;

  svg {
    margin-right: 5px;
    font-size: 25px;
    color: ${({ theme }) => theme.iconsColor};
  }

  ${({ issectionactive, theme }) => issectionactive
    && `
    border-bottom: 2px solid ${theme.secondaryColor};
    color: ${theme.secondaryColor};
    font-weight: 500;
    svg {
      color: ${theme.secondaryColor};
    }
  `}

  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.secondaryColor};
  }
`;
