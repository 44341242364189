import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  padding: 5px;
  align-self: center;
  height: 35px;
  background-color: ${({ theme }) => theme.secondaryColor};
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transition: 0.2s ease-in;

  &:hover {
    filter: brightness(0.8);
  }
}

  audio {
    margin-right: 20px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #919191;
  }

 .recording-audio-button {
    background-color: ${({ theme }) => theme.dangerButton};
  }

  .cancel-audio-button {
    background-color: ${({ theme }) => theme.backgroundColor};
    box-shadow: none;
  }

  .audio-timer {
    margin: 0 5px;
    padding: 2px 15px;
    font-size: 18px;

  }

.audio-visualizer-box {
  display: flex;
  padding: 10px 15px 10px 0;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 20px;

}
`;
