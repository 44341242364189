import styled from 'styled-components';

export const Container = styled.div`
background-color: ${({ theme }) => theme.backgroundColor};
border-right: 1px solid #DDD;
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
max-width: 480px;
overflow-x: hidden;
position: relative;

header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 5px 10px 0;
  background-color: ${({ theme }) => theme.backgroundColor};
  border-bottom: 1px solid #DDD;
}

header img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0 0 0 15px;
}

header h1 {
  width: 50%;
  font-size: 20px;
}

header button {
  background-color: transparent;
  margin-right: 15px;
}

main {
  overflow-y: auto;

&::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

&::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(0,0,0,0.2);
}
}
`;
