/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import {
  useContext,
  useState,
} from 'react';
import ModalImage from 'react-modal-image';
import CheckIcon from '@mui/icons-material/Check';
import { Dialog, DialogContent, Tooltip } from '@mui/material';
import PlayArrow from '@mui/icons-material/PlayArrow';
import DownloadIcon from '@mui/icons-material/Download';
import { formatPhoneNumber } from '../../utils/services';
import { Container } from './styles';
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';

export default function ChatMessage({
  body,
  date,
  mediaType = null,
  mediaUrl = null,
  videoThumb = null,
  isReaded,
  fromMe,
  isReceived,
}) {
  const { user } = useContext(AuthContext);
  const { currentChat } = useContext(ChatContext);

  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const handleOpenVideo = () => {
    setIsVideoOpen(true);
  };

  const handleCloseVideo = () => {
    setIsVideoOpen(false);
  };

  const setAlignItems = () => {
    if (fromMe === 1 || fromMe === 3) return 'flex-end';
    if (fromMe === 0) return 'flex-start';
    return 'center';
  };

  const getMessageContentStyle = () => {
    if (fromMe === 2) {
      return {
        backgroundColor: '#DCE0E8',
        maxWidth: '100%',
        width: '90%',
        padding: '20px',
        textAlign: 'center',
        border: '1px solid #CFD4Df',
        boxShadow: 'none',
        margin: '20px 0',
      };
    }

    if (fromMe === 3) {
      return {
        backgroundColor: '#FFFF66',
        maxWidth: '50%',
        padding: '10px',
        textAlign: '',
        boxShadow: 'none',
      };
    }

    return {
      backgroundColor: fromMe === 1
        ? '#DCF8C6'
        : '#FFFFFF',
      maxWidth: '50%',
      padding: '10px',
    };
  };

  const getSenderName = () => {
    if (fromMe === 0) {
      return currentChat.customName
      || currentChat.nome
      || formatPhoneNumber(currentChat.fromMsg);
    }
  };

  const formatMessageBody = (message) => {
    const regex = /^\*(.*?)\*:\s*/;
    const match = message.match(regex);

    const linkify = (text) => {
      const urlRegex = /((https?:\/\/)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/[^\s]*)?)/g;
      return text.replace(urlRegex, (url) => {
        const normalizedUrl = url.startsWith('http') ? url : `http://${url}`;
        return `<a href="${normalizedUrl}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    };

    if (match) {
      const boldText = match[1];
      const remainingText = message.replace(regex, '').replace(/\n/g, '<br />');

      const formattedText = linkify(remainingText);

      return (
        <>
          <i><strong>{`${boldText}:`}</strong></i>
          <br />
          <p dangerouslySetInnerHTML={{ __html: formattedText }} />
        </>
      );
    }

    return <p dangerouslySetInnerHTML={{ __html: linkify(message.replace(/\n/g, '<br />')) }} />;
  };

  return (
    <Container style={{
      alignItems: setAlignItems(),
    }}
    >
      <div
        className="message-content-div"
        style={getMessageContentStyle()}
      >
        <div className="header-message-div">
          <h4>{getSenderName()}</h4>
          <span>
            {
            currentChat.nome && fromMe === 0
              ? formatPhoneNumber(currentChat.fromMsg)
              : ''
            }
          </span>
        </div>
        {mediaType.startsWith('image') && (
          <div className="message-media-div">
            <ModalImage
              small={mediaUrl}
              large={mediaUrl}
              alt="Imagem enviada pelo usuário"
            />
          </div>
        )}
        {mediaType.startsWith('audio') && (
          <div style={{ maxWidth: '500px' }}>
            <audio controls>
              <source src={mediaUrl} type="audio/webm" />
              <track kind="captions" src="" />
            </audio>
          </div>
        )}
        {mediaType.startsWith('video') && (
          <div className="message-media-div">
            <button
              aria-label="Iniciar o vídeo"
              type="button"
              onClick={handleOpenVideo}
              style={{
                backgroundImage: `url(${videoThumb})`,
                backgroundSize: 'cover',
                position: 'relative',
              }}
            >
              <PlayArrow />
            </button>

            <Dialog
              open={isVideoOpen}
              onClose={handleCloseVideo}
            >
              <DialogContent
                style={{
                  padding: '0px',
                  backgroundColor: 'black',
                }}
              >
                <video
                  src={mediaUrl}
                  controls
                  style={{
                    width: '100%',
                    height: '70vh',
                  }}
                  autoPlay
                >
                  <track kind="captions" srcLang="pt" label="Portuguese captions" />
                </video>
              </DialogContent>
            </Dialog>
          </div>
        )}
        {mediaType.startsWith('application') && (
        <div
          className="message-media-div"
          style={{
            position: 'relative',
          }}
        >
          <embed
            type="application/pdf"
            src={mediaUrl}
            style={{
              position: 'relative',
              padding: '8px 12px',
              overflowY: 'hidden',
              width: 'auto',
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <Tooltip title="Baixar documento">
              <a
                style={{
                  padding: '8px 12px',
                  borderRadius: '4px',
                  backgroundColor: '#004E89',
                }}
                href={mediaUrl}
                download
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Baixar documento"
              >
                <DownloadIcon
                  style={{
                    color: '#F5F5F5',
                    fontSize: '30px',
                  }}
                />
              </a>
            </Tooltip>
          </div>
        </div>
        )}
        {formatMessageBody(body)}

        { fromMe !== 2 && (
        <span className="time-and-confirmation-span">
          {date}
          <span style={{ display: 'flex' }}>
            {fromMe === 1 && (
            <CheckIcon style={{ color: isReaded ? '#53BDEB' : '#919191', fontSize: '20px' }} />
            )}
            {fromMe === 1 && isReceived && (
            <CheckIcon style={{ color: isReaded ? '#53BDEB' : '#919191', fontSize: '20px', marginLeft: '-15px' }} />
            )}
          </span>
        </span>
        )}
      </div>
    </Container>
  );
}

ChatMessage.propTypes = {
  body: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isReceived: PropTypes.bool.isRequired,
  isReaded: PropTypes.bool.isRequired,
  fromMe: PropTypes.number.isRequired,
  mediaUrl: PropTypes.string,
  mediaType: PropTypes.string,
  videoThumb: PropTypes.string,
};
