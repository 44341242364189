import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  max-width: 600px;
  width: 100%;
  padding: 0 20px;

  .MuiAlert-message {
    font-size: 16px;
  }
`;
