import { useEffect, useCallback, useRef } from 'react';

const useUserActivity = (updateUserActivity, idleTimeout = 30000) => {
  const idleTimerRef = useRef(null);
  const lastActivityTimeRef = useRef(Date.now());
  const isIdleRef = useRef(false);
  const activityTimeoutRef = useRef(null);
  const debounceTimeout = 3000;
  const hasMountedRef = useRef(false);

  const handleUserActivity = useCallback(() => {
    const currentTime = Date.now();

    if (currentTime - lastActivityTimeRef.current < debounceTimeout) return;
    lastActivityTimeRef.current = currentTime;

    if (isIdleRef.current) {
      console.log('User is active after being idle');
      updateUserActivity(true);
      isIdleRef.current = false;
    }

    if (idleTimerRef.current) {
      console.log('Clearing the existing timeout.');
      clearTimeout(idleTimerRef.current);
    }

    console.log(`Setting idle timeout for ${idleTimeout} ms`);
    idleTimerRef.current = setTimeout(() => {
      console.log('Idle timer expired');
      if (!isIdleRef.current) {
        console.log('User is idle');
        isIdleRef.current = true;
        updateUserActivity(false);
      }
    }, idleTimeout);
  }, [updateUserActivity, idleTimeout, debounceTimeout]);

  useEffect(() => {
    const debouncedActivityHandler = () => {
      if (activityTimeoutRef.current) {
        clearTimeout(activityTimeoutRef.current);
      }

      activityTimeoutRef.current = setTimeout(() => {
        handleUserActivity();
      }, debounceTimeout);
    };

    if (!hasMountedRef.current) {
      console.log('User is online at mount');
      updateUserActivity(true);
      hasMountedRef.current = true;
    }

    const activityEvents = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
    activityEvents.forEach((event) => window.addEventListener(event, debouncedActivityHandler));

    return () => {
      activityEvents.forEach((event) => window
        .removeEventListener(event, debouncedActivityHandler));
      if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
      if (activityTimeoutRef.current) clearTimeout(activityTimeoutRef.current);
    };
  }, [handleUserActivity, debounceTimeout, updateUserActivity]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('User is idle (tab is hidden)');
        isIdleRef.current = true;
        updateUserActivity(false);
      } else {
        console.log('User is active (tab is visible again)');
        handleUserActivity();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleUserActivity, updateUserActivity]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      console.log('User is offline before unload');
      updateUserActivity(false);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [updateUserActivity]);
};

export default useUserActivity;
