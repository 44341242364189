import {
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  baseUrl,
  postRequest,
  patchRequest,
} from '../utils/services';

export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [registerError, setRegisterError] = useState(null);
  const [loginError, setloginError] = useState(null);
  const [isloginLoading, setIsloginLoading] = useState(false);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [registerInfo, setRegisterInfo] = useState({});
  const [needsPasswordReset, setNeedsPasswordReset] = useState(false);
  const [passwordChangeMessage, setPasswordChangeMessage] = useState('');

  const [loginInfo, setLoginInfo] = useState();

  const navigate = useNavigate();

  const updateRegisterInfo = useCallback((info) => {
    setRegisterInfo(info);
  }, []);

  const updateLoginInfo = useCallback((info) => {
    setLoginInfo(info);
  }, []);

  const updateLoginError = useCallback((error) => {
    setloginError(error);
  });

  useEffect(() => {
    const user = sessionStorage.getItem('User');
    setUser(JSON.parse(user));
  }, []);

  const registerUser = useCallback(async (e) => {
    e.preventDefault();

    setIsRegisterLoading(true);
    setRegisterError(null);

    const response = await postRequest(`${baseUrl}/users/register`, JSON.stringify(registerInfo));

    setIsRegisterLoading(false);

    if (response.error) {
      return setRegisterError(response);
    }
    sessionStorage.setItem('User', JSON.stringify(response));
    setUser(null);
    navigate('/');
  }, [setUser,
    registerInfo]);

  const loginUser = useCallback(async (e) => {
    e.preventDefault();

    setIsloginLoading(true);
    setloginError(null);

    const response = await postRequest(`${baseUrl}/users/login`, JSON.stringify(loginInfo));

    setIsloginLoading(false);

    if (response.error) {
      return setloginError(response);
    }

    if (response.needsPasswordReset) {
      setUser(response);
      setPasswordChangeMessage('É necessário redefinir senha');
      return setNeedsPasswordReset(true);
    }

    const userSession = JSON.stringify(response);

    sessionStorage.setItem('User', userSession);
    localStorage.setItem('tempSession', userSession);

    setUser(response);

    await patchRequest(
      `${baseUrl}/users/online`,
      JSON.stringify({ userId: response.id, online: 1 }),
    );
  }, [loginInfo, user]);

  useEffect(() => {
    const tempSession = localStorage.getItem('tempSession');

    if (tempSession) {
      sessionStorage.setItem('User', tempSession);
      localStorage.removeItem('tempSession');
      setUser(JSON.parse(tempSession));
    }
  }, []);

  useEffect(() => {
    if (loginError) {
      setTimeout(() => {
        setloginError(null);
      }, 5000);
    }
  }, [loginError,
    setloginError]);

  const logoutUser = useCallback(async () => {
    sessionStorage.removeItem('User');
    localStorage.removeItem('tempSession');
    setLoginInfo({
      email: '',
      password: '',
    });
    await patchRequest(
      `${baseUrl}/users/online`,
      JSON.stringify({ userId: user.id, online: 0 }),
    );
    setUser(null);
  }, [user,
    setUser]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loginUser,
        setLoginInfo,
        needsPasswordReset,
        setNeedsPasswordReset,
        isloginLoading,
        logoutUser,
        loginError,
        updateLoginError,
        loginInfo,
        updateLoginInfo,
        registerInfo,
        updateRegisterInfo,
        registerUser,
        registerError,
        isRegisterLoading,
        passwordChangeMessage,
        setPasswordChangeMessage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
