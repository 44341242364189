export const baseUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_BASE_URL
  : 'https://chat.telein.com.br/api';

export const webhookBaseUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_SERVER_WEBHOOK_URL
  : 'https://chat.telein.com.br/webhook';

export const baseMediaUrl = process.env.NODE_ENV === 'development'
  ? process.env.PROXY_DEV_S3
  : 'https://chat.telein.com.br/proxy/panfletointeligente/webhook/assinar_s3.php?mediaUrl=';

const sendMessageUrl = 'https://chat.telein.com.br/proxy/apis/enviomassa/enviarMensagem.php';

export const postRequest = async (url, body) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  });

  const data = await response.json();

  if (!response.ok) {
    let message;

    if (data?.message) {
      message = data.message;
    } else {
      message = data;
    }

    return { error: true, message };
  }

  return data;
};

export const sendMessageRequest = async (token, message, phoneNumber, mediaUrl) => {
  const body = {
    token,
    mensagem: message,
    telefone: phoneNumber,
    ...(mediaUrl && { url: mediaUrl }),
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    redirect: 'follow',
  };

  try {
    const response = await fetch(sendMessageUrl, requestOptions);
    const data = await response.json();

    if (!response.ok) {
      const message = data?.message || data;
      return { error: true, message };
    }

    return data;
  } catch (error) {
    return { error: true, message: error.message };
  }
};

export const postFormDataRequest = async (url, formData) => {
  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  const responseData = await response.json();

  if (!response.ok) {
    let message;

    if (responseData?.message) {
      message = responseData.message;
    } else {
      message = responseData;
    }

    return { error: true, message };
  }

  return responseData;
};

export const getRequest = async (url) => {
  const response = await fetch(url);

  const data = await response.json();

  if (!response.ok) {
    let message = 'An error occured!';

    if (data?.message) {
      message = data.message;
    }

    return { error: true, message };
  }

  return data;
};

export const patchRequest = async (url, body) => {
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`Error making PATCH request: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    throw new Error('Error making PATCH request:', error);
  }
};

export const deleteRequest = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error making DELETE request: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    throw new Error('Error making DELETE request:', error);
  }
};

export const formatPhoneNumber = (phone) => {
  if (!phone) return;
  const cleanPhone = phone.split('@')[0];
  const countryCode = cleanPhone.slice(0, 2);
  const areaCode = cleanPhone.slice(2, 4);
  const numberPart1 = cleanPhone.slice(4, 8);
  const numberPart2 = cleanPhone.slice(8);
  return `+${countryCode} (${areaCode}) ${numberPart1}-${numberPart2}`;
};
