import { useState } from 'react';

const formatPhoneNumber = (value) => {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 3) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`;
  }
  if (phoneNumberLength < 11) {
    return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6, 10)}`;
  }
  return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 7)}-${phoneNumber.slice(7, 11)}`;
};

const usePhoneFormatter = (initialValue = '') => {
  const [phone, setPhone] = useState(initialValue);

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    setPhone(formatPhoneNumber(value));
  };

  const getRawPhoneNumber = () => phone.replace(/[^\d]/g, '');

  return [phone, handlePhoneChange, setPhone, getRawPhoneNumber];
};

export default usePhoneFormatter;
